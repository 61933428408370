	/*=========================================================================
   	---------------------------   @Media: 1480px   ----------------------------  
	=========================================================================*/  	
	@include mq('desktop-wide-xs', $type: max) // 1480px
	{

	}
	/*=========================================================================
   	---------------------------   @Media: 1440px   ----------------------------  
	=========================================================================*/  
	@include mq('desktop-wide', $type: max) // 1440px
	{

	}
	/*=========================================================================
   	---------------------------   @Media: 1350px   ----------------------------  
	=========================================================================*/  
	@include mq('desktop-mediun', $type: max) // 1350px
	{

	}
	/*=========================================================================
   	---------------------------   @Media: 1248px   ----------------------------  
	=========================================================================*/  
	@include mq('desktop', $type: max) // 1248px
	{

	}
	/*=========================================================================
   	---------------------------   @Media: 1024px   ----------------------------  
	=========================================================================*/  
	@include mq('tablet-wide', $type: max) // 1024px
	{

	}
	/*=========================================================================
   	---------------------------   @Media: 768px   ----------------------------  
	=========================================================================*/  
	@include mq('tablet', $type: max) // 768px
	{
		.section-who{
			.who{
				h3{
					text-align: center;
				}
			}
		}

	}
	/*=========================================================================
   	---------------------------   @Media: 640px   ----------------------------  
	=========================================================================*/  
	@include mq('tablet-small', $type: max) // 640px
	{
	.newsletter{
		h4,h2{
			text-align: center;
		}
			ul{
				 display: flex;
    			justify-content: space-around;

				li{
					
					i{
					margin-right: margin-global(m6);
					margin-bottom: margin-global(m1);
						
					}
					span{
							text-align: center;
						}
				}
			}
		}
	}
	/*=========================================================================
   	---------------------------   @Media: 560px   ----------------------------  
	=========================================================================*/  
	@include mq('phablet', $type: max) // 560px
	{

	}
	/*=========================================================================
   	---------------------------   @Media: 480px   ----------------------------  
	=========================================================================*/  
	@include mq('phone-wide', $type: max) // 480px
	{
		.newsletter{
		h4,h2{
			text-align: center;
		}
			ul{
				    width: 138px;
    margin: 0 auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

				li{
					
					i{
					margin-right: margin-global(m6);
					margin-bottom: margin-global(m1);
						
					}
					span{
							text-align: center;
						}
				}
			}
		}

 
	}
	/*=========================================================================
   	---------------------------   @Media: 400px   ----------------------------  
	=========================================================================*/  
	@include mq('phone', $type: max) // 400px
	{

	}
	/*=========================================================================
   	---------------------------   @Media: 340px   ----------------------------  
	=========================================================================*/  
	@include mq('phone-small', $type: max) // 340px
	{

	}
	/*=========================================================================
   	---------------------------   @Media: 320px   ----------------------------  
	=========================================================================*/  
	@include mq('phone-min-small', $type: max) // 320px
	{

	}
	/*=========================================================================
   	---------------------------   @Media: 260px   ----------------------------  
	=========================================================================*/  
	@include mq('phone-min', $type: max) // 260px
	{

	}

