@mixin shadow($level: 1, $background: black) {
  @if $level == 1 { box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.1); }
  @else if $level == 1.2 { box-shadow: 00 2px 7px -3px rgba(0, 0, 0, 0.1) }
  @else if $level == 1.3 {box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1),0 3px 1px -2px rgba(0, 0, 0, 0.1),0 1px 5px 0 rgba(0, 0, 0, 0.1);}
  @else if $level == 1.5 { box-shadow: 3px 3px 6px 0 rgba(0,0,0,0.04);}
  @else if $level == 1.75 {box-shadow: 3px 3px 20px 0 rgba(0,0,0,0.14);}
  @else if $level == 2 { box-shadow: 0 3px   6px rgba(0,0,0,0.16), 0   3px 6px rgba($background,0.23) }
  @else if $level == 3 { box-shadow: 0 16px 26px -5px rgba(0, 0, 0, 0.25); }
  @else if $level == 4 { box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba($background,0.22) }
  @else if $level == 5 { box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba($background,0.22) }

  @else if $level = 20 {     box-shadow: 0 0 40px rgba(0, 0, 0, 0.15); }

}


@mixin box_shadow ($level) {

    @if $level == 1 {
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    } @else if $level == 2 {
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    } @else if $level == 3 {
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    } @else if $level == 4 {
          box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    } @else if $level == 5 {
        box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    } @else if $level == 6 {
        box-shadow: 0 0.25rem 0.125rem 0 rgba(0,0,0,.05);
    }

}