//Main wrapper
.main-wrap{
	min-height: 100%;
	.home-page{
		min-height: 100vh;
	}
	.section-home{
		display: flex;
		justify-content: center;
		align-items: center;
		.left-title {
		    text-align: left;
		    max-width: 1020px;
		    width: 100%;
		    max-width: 720px;
		 
		}
		.section-wrapper {
		    width: 100%;
		    max-width:1140px;
		    padding-left: 16px;
		    padding-right: 16px;
		    margin-left: auto;
		    margin-right: auto;
		    position: relative;

		}
		

	}
	.home-padding {
		 padding: 40px 16px;

	}	
	.title-desc{
		margin-bottom: margin-global(m2)
	}

	//btn
	.btn {
	    display: inline-block;
	    font-weight: 400;
	    text-align: center;
	    white-space: nowrap;
	    vertical-align: middle;
	    -webkit-user-select: none;
	    -moz-user-select: none;
	    -ms-user-select: none;
	    user-select: none;
	    border: 1px solid transparent;
	    padding: .5rem .75rem;
	    font-size: 1rem;
	    line-height: 1.25;
	    border-radius: .25rem;
	    transition: all .15s ease-in-out;
	}
	.btns-action .btn {
    margin-bottom: 8px;
	}
	.btn.no-border {
	    border: none;
	}
	.btn-shadow {
	    -webkit-box-shadow: 0 2px 24px rgba(0, 0, 0, 0.2);
	    box-shadow: 0 2px 24px rgba(0, 0, 0, 0.2);
	    -webkit-transition: 0.3s;
	    transition: 0.3s;
	}

	
	.btn {
	    color: #ffffff;
	    border: 1px solid transparent;
	    background: scotch-color('danger', 'base');
	    background: -webkit-linear-gradient(left, scotch-color('danger', 'base') 0,scotch-color('danger', 'light') 100%);
	    background: -moz-linear-gradient(left, scotch-color('danger', 'base') 0,scotch-color('danger', 'light') 100%);
	    background: linear-gradient(to right, scotch-color('danger', 'base') 0,scotch-color('danger', 'light') 100%);
	    -webkit-transition: 0.3s;
	    transition: 0.3s;
	    border-radius: 30px;

	}
	.btn-white {
	    color: #ffffff;
	    border: 1px solid transparent;
	    background-color: #ffffff;
	}
	.btn-round {
	    border-radius: 10rem;
	}
	.btn {
	    padding: 0.75rem 1.5rem;
	    cursor: pointer;
	    min-height: 46px;
	    line-height: 1.2;
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box;
	    display: inline-block;
	    position: relative;
	}
	
	.btn-outline-white {
	    color: #ffffff;
	    border: 2px solid #ffffff;
	    -webkit-transition: 0.3s;
	    transition: 0.3s;
	    background: none;
	    line-height: 1;
	}
	.btn-outline-white:hover, .btn-outline-white:focus {
	    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
	    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
	}

	.btn-outline-white:hover, .btn-outline-white:focus {
	    color: rgba(255, 255, 255, 0.5);
	    border-color: rgba(255, 255, 255, 0.5);
	    background-color: transparent;
	}
	.btn:hover, .btn:focus {
	    color: #ffffff;
	    background-color: #8e233e;
	    border-color: #8e233e;
	    opacity: 0.9;
	}
}


.section-home .section-content .illustr img.portrait {
    max-height: 400px;
    width: auto;
}
@media (min-width: 768px){
.section-home .section-content .illustr img.portrait {
    max-height: 66vh;
    width: auto;
}
}
.section-home .section-content .illustr img {
    width: 100%;
}
.section-content .illustr img.portrait {
    max-height: 66vh;
    width: auto;
}
.section-content .illustr img {
    width: 100%;
}	

.owl-dots{
    display: flex;
    justify-content: center;
}
.owl-dots .owl-dot span {
    background: none repeat scroll 0 0 #869791;
    border-radius: 20px;
    display: block;
    height: 12px;
    margin: 5px 7px;
    opacity: 0.5;
    width: 12px;
 
}
.owl-dot.active span{
	background-color: #fff
}