@include mq('tablet'){
.section-home {
	.home-padding {
    padding: 96px 0;
	}
}
}



@media (min-width: 768px){
	.section-home {
		.section-wrapper {
		    padding-left: 0;
		    padding-right: 0;
		}
	}
}
.home-page .section-wrapper .btns-action {
    text-align: center;
}
@media (min-width: 768px){
	.home-page .section-wrapper .btns-action {
	    text-align: inherit;
	}
}



@media (min-width: 576px){
.btns-action {
    margin-left: -2px;
    margin-right: -2px;
}
}
.home-page .section-wrapper .btns-action .btn {
    min-width: 67%;
}

@media (min-width: 768px){
.home-page .section-wrapper .btns-action .btn {
    min-width: 144px;
}
}

@media (min-width: 576px){
.btns-action .btn {
    min-width: 132px;
    width: auto;
    margin-left: 2px;
    margin-right: 10px;
}
}



