	@include mq('phone-min', $type: max) // 260px
	{

	}
	@include mq('phone-min-small', $type: max) // 320px
	{

	}
	@include mq('phone-small', $type: max) // 340px
	{

	}
	@include mq('phone', $type: max) // 400px
	{
		.avatar-content{
			flex-direction: column!important;
		
			.avatar-img{
				margin-right: 0 !important;
				margin-bottom: 20px;
		
			}
			.avatar-text{
				text-align: center;
				padding: 0 !important
			}
		}
		.spacing-col{
			margin: margin-global(m8) + margin-global(m8) 0 !important;
		}
		.best-title{
			font-size: 40px !important;
		}
		.details-img {
		    width: 100%;
		    height: 76% !important;
		}

	}
	@include mq('phone-wide', $type: max) // 480px
	{

	}
	@include mq('phablet', $type: max) // 560px
	{

	}
	@include mq('tablet-small', $type: max) // 640px
	{

	}
	@include mq('tablet', $type: max) // 768px
	{
		.best-title{
			text-align: center;
		}
	}
	@include mq('tablet-wide', $type: max) // 1024px
	{

	}
	@include mq('desktop', $type: max) // 1248px
	{

	}
	@include mq('desktop-mediun', $type: max) // 1350px
	{

	}
	@include mq('desktop-wide', $type: max) // 1440px
	{
		.img-flex{
			display: flex;
			justify-content: center;
			align-items:center;
			img{

			}
		}

	}
	@include mq('desktop-wide-xs', $type: max) // 1480px
	{

	}	