// Typography Mixins
// --------------------------------------------------
@mixin typography($font-size){
  $base-line-height: $font-size * $ratio;
  $margin: $base-line-height;
  body{
    font-size: $font-size;
    line-height: ($font-size * $ratio);
  }
  h1, .h1 {
    font-size: ((($font-size * $ratio) * $ratio) * $ratio);
    line-height: ($font-size * $ratio) * 3;
    margin-top: $margin * 2;
    margin-bottom: $margin;
  }

  h2, .h3 {
    font-size: (($font-size * $ratio) * $ratio);
    line-height: ($font-size * $ratio) * 2;
    margin-top: $margin;
    margin-bottom: $margin;
  }

  h3, .h3 {
    font-size: $font-size * $ratio;
    line-height: ($font-size * $ratio);
    margin-top: $margin;
    margin-bottom: $margin;
  }
  h4, .h4 {
    font-size: $font-size;
    line-height: ($font-size * $ratio);
    margin-top: $margin;
    margin-bottom: $margin;
  }
  p, ul, ol, pre, table, blockquote{
    margin-top: $margin;
    margin-bottom: $margin;
  }
}

$ratio: 1.618; // Golden Ratio
/*@media (max-width: 299px){
  @include typography(12px);
}
@media (min-width: 300px){
  @include typography(14px);
}
@media(min-width: 600px){
  @include typography(15px);
}
@media(min-width: 800px){
  @include typography(16px);
}
@media(min-width: 1000px){
  @include typography(19px);
}
@media(min-width: 1200px){
  @include typography(21px);
}
@media(min-width: 1400px){
  @include typography(23px);
}
@media(min-width: 1600px){
  @include typography(25px);
}*/
//Gotham_Light_Rounded
$font-universal: Gotham_Light_Rounded;
@mixin font-uni($fw: 400, $font: $font-universal){
      font-family: $font;
      font-weight: $fw;
}
@mixin font-lato($fw: 400){
      font-family: Lato,"sans-serif";
      font-weight: $fw;
}
@mixin font-hei() {
  font-family: -apple-system, "Helvetica Neue", Helvetica, "Nimbus Sans L", Arial, "Liberation Sans", "PingFang SC", "Hiragino Sans GB", "Source Han Sans CN", "Source Han Sans SC", "Microsoft YaHei", "Wenquanyi Micro Hei", "WenQuanYi Zen Hei", "ST Heiti", SimHei, "WenQuanYi Zen Hei Sharp", sans-serif;
}

@mixin font-kai() {
  font-family: Baskerville, Georgia, "Liberation Serif", "Kaiti SC", STKaiti, "AR PL UKai CN", "AR PL UKai HK", "AR PL UKai TW", "AR PL UKai TW MBE", "AR PL KaitiM GB", KaiTi, KaiTi_GB2312, "TW\-Kai", serif;
}

@mixin font-song() {
  font-family: Georgia, "Nimbus Roman No9 L", "Songti SC", STSong, "AR PL New Sung", "AR PL SungtiL GB", NSimSun, SimSun, "TW\-Sung", "WenQuanYi Bitmap Song", "AR PL UMing CN", "AR PL UMing HK", "AR PL UMing TW", "AR PL UMing TW MBE", PMingLiU, MingLiU, serif;
}

@mixin font-fang-song() {
  font-family: Baskerville, "Times New Roman", "Liberation Serif", STFangsong, FangSong, FangSong_GB2312, "CWTEX\-F", serif;
}
@mixin font-Raleway() {
font-family: 'Raleway', sans-serif;
}
@mixin font-bold(){
  font-family: Gotham_Bold  !important;
}
@mixin font-light(){
  font-family: Gotham_light  !important;
}
@mixin font-bold-rounded(){
  font-family: Gotham_Bold_Rounded !important;
}
@mixin font-light-rounded(){
  font-family: Gotham_Light_Rounded  !important;
}
@mixin font-lato-import(){
  @import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');
}
@mixin font-pluto{
  font-family: Pluto !important;
}
@mixin font-pluto-import(){
  
  @font-face {
        font-family: Pluto;
        src: url(../../../font/Pluto/Pluto_Sans_Bold.otf) format('opentype');
        font-weight: 400;
        font-style: normal
    }
}
@mixin font-portafolio(){
    @font-face {
        font-family: Gotham_Bold;
        src: url(../fonts/Gotham/Gotham-Bold.otf) format('opentype');
        font-weight: 400;
        font-style: normal
    }

    @font-face {
        font-family: Gotham_Light;
        src: url(../fonts/Gotham/Gotham-Light.otf) format('opentype');
        font-weight: 400;
        font-style: normal
    }
     @font-face {
        font-family: Gotham_Bold_Rounded;
        src: url(../fonts/Gotham/Gotham-Rounded-Bold.otf) format('opentype');
        font-weight: 400;
        font-style: normal
    }

    @font-face {
        font-family: Gotham_Light_Rounded;
        src: url(../fonts/Gotham/Gotham-Rounded-Light.ttf) format('opentype');
        font-weight: 400;
        font-style: normal
    }

}
@mixin font-gordita(){
 font-family: 'gordita';
}
@mixin font-gordita-folder{
     @font-face {
    font-family: gordita;
    src: url('../fonts/Gordita/hinted-Gordita-Regular.eot');
   
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: gordita;
    src: url('../fonts/Gordita/hinted-Gordita-Light.eot');

  }

  @font-face {
    font-family: gordita;
    src: url('../fonts/Gordita/hinted-Gordita-Medium.eot');
  }
}
@mixin font-gordi(){
    @font-face {
    font-family: 'gordita';
    src: url('https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Regular.eot');
    src: url('https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Regular.eot?#iefix') format('embedded-opentype'),
        url('https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Regular.woff2') format('woff2'),
        url('https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Regular.woff') format('woff'),
        url('https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Regular.ttf') format('truetype'),
        url('https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Regular.svg#Gordita-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'gordita';
    src: url('https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Light.eot');
    src: url('https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Light.eot?#iefix') format('embedded-opentype'),
        url('https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Light.woff2') format('woff2'),
        url('https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Light.woff') format('woff'),
        url('https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Light.ttf') format('truetype'),
        url('https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Light.svg#Gordita-Light') format('svg');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'gordita';
    src: url('https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Medium.eot');
    src: url('https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Medium.eot?#iefix') format('embedded-opentype'),
        url('https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Medium.woff2') format('woff2'),
        url('https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Medium.woff') format('woff'),
        url('https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Medium.ttf') format('truetype'),
        url('https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Medium.svg#Gordita-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
  }

}



/**
 * TODO: REMOVE
 */
/*@font-face {
  font-family: "proxima-nova";
  src: url("https://fonts.luna1.co/proxima-nova/ProximaNova-RegWeb.eot");
  src: url("https://fonts.luna1.co/proxima-nova/ProximaNova-RegWeb.eot#iefix") format("embedded-opentype"),
       url("https://fonts.luna1.co/proxima-nova/ProximaNova-RegWeb.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "haptik";
  src: url("https://fonts.luna1.co/haptik/GT-Haptik-Regular.eot");
  src: url("https://fonts.luna1.co/haptik/GT-Haptik-Regular.eot#iefix") format("embedded-opentype"),
       url("https://fonts.luna1.co/haptik/GT-Haptik-Regular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "asanaIcons";
  src: url("https://fonts.luna1.co/icons/asanaIcons.eot");
  src: url("https://fonts.luna1.co/icons/asanaIcons.eot#iefix") format("embedded-opentype"),
       url("https://fonts.luna1.co/icons/asanaIcons.woff") format("woff");
  font-weight: 200;
}
*/
@mixin haptik(){
  @font-face {
  font-family: "haptik";
  src: url("https://s3.amazonaws.com/asanafonts/haptik/GT-Haptik-Light.eot");
  src: url("https://s3.amazonaws.com/asanafonts/haptik/GT-Haptik-Light.eot#iefix") format("embedded-opentype"),
       url("https://s3.amazonaws.com/asanafonts/haptik/GT-Haptik-Light.woff") format("woff");
  font-weight: 200;
}
}

// Sample HTML + Body Styles
///////////////////////////////////////////////////
// html {
//     font-size: 100%;
//     -webkit-text-size-adjust: 100%;
//     -ms-text-size-adjust: 100%;
//     -webkit-font-smoothing: antialiased !important;
//     text-rendering: optimizelegibility;
//     -moz-osx-font-smoothing: grayscale;
// }
// body{
//   font-family: 'Helvetica Neue', sans-serif;
//   color: rgb(33,33,33);
//   margin: 0 auto 2em;
// }
@function padding-rem($px) {
  @return ($px / 16px) * 1rem;
}
@function magin-rem($px) {
  @return ($px / 16px) * 1rem;
}
@function rem($px) {
  @return ($px / 16px) * 1rem;
}
@function rem-margin($px) {
  @return ($px / 12px) * 1rem;
}

$padding-global: (
  p1   : padding-rem(36px),
  p2   : padding-rem(24px),
  p3   : padding-rem(20px),
  p4   : padding-rem(16px),
  p5   : padding-rem(14px),
  p6   : padding-rem(12px),
  p7   : magin-rem(8px),
  p8   : magin-rem(6px),
  p9   : magin-rem(4px),
  p10   : magin-rem(2px)
);
$margin-global: (
  m1   : magin-rem(36px),
  m2   : magin-rem(24px),
  m3   : magin-rem(20px),
  m4   : magin-rem(16px),
  m5   : magin-rem(14px),
  m6   : magin-rem(12px),
  m7   : magin-rem(8px),
  m8   : magin-rem(6px),
  m9   : magin-rem(4px),
  m10   : magin-rem(2px)
);
$fontSizes-margin: (
  h1   : rem(36px),
  h2   : rem(24px),
  h3   : rem(20px),
  h4   : rem(16px),
  h5   : rem(14px),
  h6   : rem(12px)
);
$fontSizes: (
  h1   : rem(36px),
  h2   : rem(24px),
  h3   : rem(20px),
  h4   : rem(16px),
  h5   : rem(14px),
  h6   : rem(12px)
);
$prisma-sz: (
  h0   : rem(70px),
  h1   : rem(40px),
  h2   : rem(28px),
  h3   : rem(20px),
  h4   : rem(19px),
  h4.5   : rem(15px),
  h5   : rem(14px),
  h6   : rem(12px)

);


@function prisma-sz($size) {
  @if map-has-key($prisma-sz, $size) {
    @return map-get($prisma-sz,$size)
  } @else {
    @error '$size is not valid, valid values are t1, t2, t3, normal, small, smaller';
  }
}
@function padding-global($size) {
  @if map-has-key($padding-global, $size) {
    @return map-get($padding-global,$size)
  } @else {
    @error '$size is not valid, valid values are t1, t2, t3, normal, small, smaller';
  }
}
@function margin-global($size) {
  @if map-has-key($margin-global, $size) {
    @return map-get($margin-global,$size)
  } @else {
    @error '$size is not valid, valid values are t1, t2, t3, normal, small, smaller';
  }
}
@function fontSize($size) {
  @if map-has-key($fontSizes, $size) {
    @return map-get($fontSizes,$size)
  } @else {
    @error '$size is not valid, valid values are t1, t2, t3, normal, small, smaller';
  }
}
@function fontSize-margin($size) {
  @if map-has-key($fontSizes, $size) {
    @return map-get($fontSizes,$size)
  } @else {
    @error '$size is not valid, valid values are t1, t2, t3, normal, small, smaller';
  }
}

h1, .h1{
 font-size: fontSize(h1);
 margin-top: 0;
 margin-bottom: fontSize-margin(h3);
 color: scotch-color('app', '2')
}
h2, .h2{
 font-size: fontSize(h2);
 margin-top: 0;
 margin-bottom: fontSize-margin(h3);
 color: scotch-color('app', '2')
}
h3, .h3{
 font-size: fontSize(h3);
 margin-top: 0;
 margin-bottom: fontSize-margin(h3);
 color: scotch-color('app', '2')
}
h4, .h4{
 font-size: fontSize(h4);
 margin-top: 0;
 margin-bottom: fontSize-margin(h4);
 color: scotch-color('app', '2')
}
h5, .h5{
 font-size: fontSize(h5);
 margin-top: 0;
 margin-bottom: fontSize-margin(h3);
 color: scotch-color('app', '2')
}
h6, .h6{
 font-size: fontSize(h6);
 margin-top: 0;
 margin-bottom: fontSize-margin(h3);
 color: scotch-color('app', '2')
}

.f-1{
 font-size: fontSize(h1) !important;

}
.f-2{
 font-size: fontSize(h2) !important;

}
.f-3{
 font-size: fontSize(h3) !important;

}
.f-4{
 font-size: fontSize(h4) !important;

}
.f-5{
 font-size: fontSize(h5) !important;

}
.f-6{
 font-size: fontSize(h6) !important;

}
