.bg-gradient-secondary {
    background-image: linear-grandient(to left, scotch-color('danger', 'base'), scotch-color('primary', 'light'));
	background-image: -webkit-gradient(linear, right top, left top, from(scotch-color('primary', 'base')), to(scotch-color('primary', 'light')));
}
.landing-cta {
    background: url(../img/bg-wave-shadow.png), -webkit-gradient(linear, right top, left top, from(scotch-color('primary', 'base')), to(scotch-color('primary', 'light')));
    background: url(../img/bg-wave-shadow.png), linear-gradient(to left,scotch-color('primary', 'base'), scotch-color('primary', 'light'));
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;
}

.landing-cta-2 {
    background: url(../img/bg-wave-rotate-shadow.png), -webkit-gradient(linear, right top, left top, from(scotch-color('primary', 'base')), to(scotch-color('primary', 'light')));
    background: url(../img/bg-wave-rotate-shadow.png), linear-gradient(to left,scotch-color('primary', 'base'), scotch-color('primary', 'light'));
    background-repeat: no-repeat;
    background-position: center top;
      background-size: cover;
          padding: 80px;
}

