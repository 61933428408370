.section-status{
	background: #ffffff;
	.status{
		.icon{
			margin-bottom: margin-global(m3);
		}
		.text-right{
			text-align: right;
			margin-bottom: margin-global(m3);
		}
		.text-left{
			text-align: left;
			margin-bottom: margin-global(m3);
		}
		.position-relative{
			
			.mw-100 {
				    max-width: 100%;
				        z-index: 2;
    position: relative;
				}
				.ts-svg {
			   
			    top: 0;
			    z-index: 1;
			    
			   
			}
			.ts-svg__organic-shapde-01{
				
   				width: 100%;

			}
		}
	}
}

.wave {
  opacity: 0;
  position: absolute;
  top: 3%;
  left: 43%;
    background:scotch-color('primary', 'base');
    background: -webkit-linear-gradient(left, scotch-color('primary', 'base') 0,scotch-color('primary', 'light') 100%);
    background: -moz-linear-gradient(left, scotch-color('primary', 'base') 0,scotch-color('primary', 'light') 100%);
    background: linear-gradient(to right, scotch-color('primary', 'base') 0,scotch-color('primary', 'light') 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f54ea2',endColorstr='#ff7676',GradientType=1);
  width: 330px;
  height: 330px;
  margin-left: -156px;
  margin-top: 156px;
 
  transform-origin: 50% 48%;
  border-radius: 43%;
  animation: drift 3000ms infinite linear;
}

.wave.-three {
  animation: drift 5000ms infinite linear;
}

.wave.-two {
  animation: drift 7000ms infinite linear;
  opacity: 0;
  background: yellow;
}

.box:after {
  content: '';
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(#e8a, 1), rgba(#def, 0) 80%, rgba(white, .5));
  z-index: 11;
  transform: translate3d(0, 0, 0);
}


@keyframes drift {
  from { transform: rotate(0deg); }
  from { transform: rotate(360deg); }
}