$breakpoints: (
    "phone-min":            260px,
    "phone-min-small":      320px, 
    "phone-small":          340px, 
    "phone":                400px,
    "phone-wide":           480px,
    "phablet":              560px,
    "tablet-small":         640px,
    "tablet":               768px,
    "tablet-wide":          1024px,
    "desktop":              1248px,
    "desktop-mediun":       1350px,
    "desktop-wide":         1440px,
    "desktop-wide-xs":      1480px
);
@mixin mq($width, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == max {
            $width: $width - 1px;
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}



@mixin breakpoint($point)       {
    @if $point == retina {
        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { @content; }
    }
    @if $point == xxlarge {
        @media (min-width: 1921px) { @content; }
    }
    @if $point == xlarge {
        @media (min-width: 1600px) { @content; }
    }
    @if $point == large {
        @media (min-width: 1441px) { @content; }
    }
    @else if $point == regular-screen {
        @media (min-width: 1200px) { @content; }
    }
    @else if $point ==  medium {
        @media (min-width: 1024px)  { @content; }
    }
    @else if $point ==  landscape {
        @media (min-width: 1024px)  { @content; }
    }
    @else if $point ==  smaller-screen {
        @media (min-width: 800px)  { @content; }
    }
    @else if $point ==  portrait {
        @media (min-width: 768px)  { @content; }
    }
    @else if $point ==  small {
        @media (min-width: 640px)  { @content; }
    }
    @else if $point ==  large-mobile {
        @media (min-width: 480px)  { @content; }
    }
    @else if $point ==  mobile {
        @media (min-width: 360px)  { @content; }
    }
}