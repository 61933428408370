.section .section-wrapper {
    width: 100%;
    max-width: 1020px;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

@media (min-width: 768px){
.icon-4 {
    font-size: 3.5rem;
}
}