
@media (min-width: 768px){
    .section .section-wrapper {
        padding-left: 0;
        padding-right: 0;
    }
}
.section .padding-bottom {
    padding-bottom: 32px;
}
@media (min-width: 768px){
    .section .padding-bottom {
        padding-bottom: 64px;
    }
}
@media (min-width: 992px){
    .section .padding-bottom {
        padding-bottom: 80px;
    }
}
.section .padding-top {
    padding-top: 32px;
}
@media (min-width: 768px){
.section .padding-top {
    padding-top: 64px;
}
}

@media (min-width: 992px){
.section .padding-top {
    padding-top: 80px;
}
}




 .title-desc {
    margin-bottom: 2rem;
    p{
        margin: 0;
    }
}

@media (min-width: 768px){
.title-desc.reduced {
    max-width: 1198px;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: auto;
    margin-right: auto;
}
}


.description{
  .card{
    display: block;
    padding: padding-global(p2) padding-global(p5);
    @include trans(box-shadow .3s);
    @include shadow(1.3);
    &:hover{
        @include shadow(20);
    }
    p{
        margin-top: 0;
        color: scotch-color('app', '2');
        margin-bottom: margin-global(m4);
    }
    h4{
         font-size: fontSize(h3);
    }
    .item-icon{
            text-align: center;
    }
    .material-icons{
        margin-bottom: margin-global(m4);
    }

  } 
  
}


.icon-4 {
    font-size: 3.5rem !important;
    font-weight: 300;
    line-height: 1.1;
}

