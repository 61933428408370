.section-who{
	.who{
		h2{
			
		}
	}
}

.newsletter{
	ul{
		li{
			i{
				margin-right: margin-global(m1);
				margin-bottom: margin-global(m4);
				span{

				}
			}
		}
	}
}

	