.footer .navbar-brand {
    color: scotch-color('app', '2');
    margin-bottom: margin-global(m4);

}

.navbar-brand {
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}

.footer{
        .navbar-brand img {
        height: 48px;
        width: auto;
        margin-right: margin-global(m4) + margin-global(m6);
    }
    .navbar-brand .text {
        color: scotch-color('app', '2');
        margin-bottom: 0;
        font-weight: 400;
        text-transform: uppercase;
        font-size: fontSize(h2);
  
    }
    .navbar-nav {
        li{
          margin-right: margin-global(m1) + margin-global(m6);  
           &:last-child(){
                margin-right:0;
            }
          a{
            color: scotch-color('app', '2');
          }
        }
    }
    .icon-nav-footer{
        display: flex;
        justify-content: center;
        li{
            margin-top: margin-global(m1);
            margin-right: margin-global(m1) + margin-global(m6);   
            &:last-child(){
                margin-right:0;
            }
            a{
                color: scotch-color('app', '2');
                i{
                    font-size: fontSize(h1) - fontSize(h6);
                }
                img{
                    min-height: 20px;
                    height: 30px;
                    max-width: auto;
                }
            }
        }
    }
    .desc-footer{
      p{
          margin-bottom: 0;
          margin-top: margin-global(m1);
          color: scotch-color('app', '2');
          a{
           color: scotch-color('app', '1'); 
                background: #29b6f6;
                background: -webkit-gradient(linear, left top, right top, color-stop(0, #29b6f6), to(#4b7bec));
                background: -o-linear-gradient(left, #29b6f6 0, #4b7bec 100%);
                background: linear-gradient(to right, #29b6f6 0, #4b7bec 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f54ea2',endColorstr='#ff7676',GradientType=1);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
          }
      }  
    }
}