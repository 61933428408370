	@include mq('phone-min', $type: max) // 260px
	{

	}
	@include mq('phone-min-small', $type: max) // 320px
	{

	}
	@include mq('phone-small', $type: max) // 340px
	{

	}
	
	@include mq('phone-wide', $type: max) // 480px
	{	
		.landing-cta-2{
			padding-bottom: 0;
			padding-top: 63px;
		}
		.mouse-whell{
			bottom: -8px;
		}
		.footer-nav{
			ul{
				li{
					margin-right: 1.5rem !important;
					a{

					}
					&:last-child{
						margin-right: 0 !important;					}
				}
			}
		}

	}
	@include mq('phone', $type: max) // 400px
	{
		.contact-padding{
			 padding: padding-global(p4) padding-global(p4) padding-global(p4)  padding-global(p4);
		}
		.mouse-whell{
			bottom: -31px;
		}

	}
	
	@include mq('tablet-small', $type: max) // 640px
	{
		
		.section-home .section-content .illustr img.portrait{
			max-height: 300px;
		}
	}
	@include mq('tablet', $type: max) // 768px
	{
	.owl-next{
		right: 0;

		transform: none;
		top: 100%;

	}
	.owl-prev{
		left: 0;

		transform: none;
		top: 100%;
	}
	}
	@include mq('phablet', $type: max) // 560px
		{
			.owl-next{
			right: 0;

			transform: none;
			top:80%;

		}
		.owl-prev{
			left: 0;

			transform: none;
			top: 80%;
		}


	}
	@include mq('tablet-wide', $type: max) // 1024px
	{
		
	}
	@include mq('desktop', $type: max) // 1248px
	{
		.home-page{
			.text-left{
				.section-content{
					text-align: center;
				}
			}
			
		}
	}
	@include mq('desktop-mediun', $type: max) // 1350px
	{

	}
	@include mq('desktop-wide', $type: max) // 1440px
	{

	}
	@include mq('desktop-wide-xs', $type: max) // 1480px
	{

	}