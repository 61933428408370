@include mq('tablet-wide', $type: max)
{
    
}

@include mq('tablet', $type: max)
 {
    .card{
        margin-bottom:  margin-global(m2);
    }

    .section-title-01 .subtitle, .section-title-02 .subtitle{
    	font-size: 66px;
    }
    .section-title-01{
    	text-align: center;
    }
    hr.hr-title-03{
    	margin: 0 auto 50px auto;
    }
    hr.hr-title-04{
    	margin: 0 auto 50px auto;
    }
    .o-md-1{
    	order: 1
    }
    .o-md-2{
    	order: 2
    }
    .o-md-1{
    	order: 3
    }
	.subscribe-btn{
		margin: 0 auto margin-global(m2) auto !important;
	
	}
	.form-control{
		     margin-bottom:  margin-global(m2);
	}
    

}

@include mq('tablet-small', $type: max)
 {
	.section-title-01 .subtitle, .section-title-02 .subtitle{
    	font-size: 55px;
    }
    .swiper-container-3d .swiper-slide{
        padding: 0 padding-global(p3);
    }
    .swiper-slide img{
          width: 100%;  
    }
 }


@include mq('phone-wide', $type: max)
 {
 	.section-title-01 .subtitle{
    	left: 50%;
        transform: translate(-50%, 50%);
    }
    .section-title-02 .subtitle{
    	left: 50%;
        transform: translate(-50%, 50%);
        top: 35%;
    }
 	.section-title-01 .subtitle, .section-title-02 .subtitle{
    	font-size: 40px;
    }	
    .screen_wrap {
        width: 331px;
        padding: 0 padding-global(p3);
    }

   
 }

@include mq('phone-small', $type: max)
 {
 	.footer .navbar-nav li{
 		margin-right: .5rem;
 	}
     .screen_wrap {
        width: 275px;
        padding: 0 padding-global(p3);
    }
    .swiper-container-3d .swiper-slide{
        width: 0;
        padding: 0;
    }
 }