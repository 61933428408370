.page-header-alpha {

    justify-content: flex-start;
      @include trans(color .3s, $cubic-1);
      .all-menu-wrapper {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    position: fixed;
    z-index: 5;
    background: #1d1d1d;
    top: -100%;
    left: 0;
    width: 100%;
    max-height: 100%;
    overflow: auto;
    padding: 48px 32px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15), 0 0 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15), 0 0 5px rgba(0, 0, 0, 0.2);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

    .navbar-brand{

        display: flex;
        flex-direction: row;
        padding: 0;
        @include trans(all .3s, $cubic-1);
        color: currentColor;
        .text {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            color: currentColor;
            font-size: 1rem;
            margin-left: 8px;
            margin-right: 8px;
            .line{
                color: #fff;
            }
        }
        .logo {
            position: relative;
            img {
                height: 40px;
                width: auto;
                -webkit-transition: 0.15s;
                transition: 0.15s;
                }
        }
        
    }
   
    .navbar-topmenu {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
    }


    .navbar-nav {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }

    .navbar-nav-menu .nav-item a {
        color: #ffffff;
        opacity: 0.5;
    }

    .navbar-nav-menu .nav-item.active a {
       color: #ffffff;
        opacity: 1;
    }
    
    .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0;
    }
    .nav-link {
        display: block;
        padding: .5rem 1rem;
    }
   
    &.scroll{
        .navbar-nav-menu .nav-item.active  a{
            color: scotch-color('app', '2');
        }
        .navbar-nav-menu .nav-item a{
            color: scotch-color('app', '2');
        }
        .text{
            .line{
                 color: scotch-color('app', '2');
            }
        }
        .gradient-text-04 {
                background:scotch-color('primary', 'base');
                background: -webkit-linear-gradient(left, scotch-color('primary', 'base') 0,scotch-color('primary', 'light') 100%);
                background: -moz-linear-gradient(left, scotch-color('primary', 'base') 0,scotch-color('primary', 'light') 100%);
                background: linear-gradient(to right, scotch-color('primary', 'base') 0,scotch-color('primary', 'light') 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f54ea2',endColorstr='#ff7676',GradientType=1);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
             .navbar-nav-menu .nav-item:last-child a{
                opacity: 1;
            }
    }
     .menu-visible{
       .nav-item.active  a{
        color: #ffffff !important ;
        opacity: 1;
       }
    }
    .menu-visible{
      .nav-item a {
        color: #ffffff !important;
        opacity: 0.5;
        }   
    }
   

}
.navbar {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: .5rem 1rem;
}

.page-header-alpha .navbar-toggler {
    position: fixed;
    top: 8px;
    right: 16px;
    padding: 0;
    border: 0;
    z-index: 999;
    display: block;
    cursor: pointer;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background: 0 0;
    border: 1px solid transparent;
    border-radius: .25rem;
}
.menu-i {
    height: 40px;
    width: 40px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    display: inline-block;
    position: relative;
    background: #ffffff;
    border-radius: 2px;
}

.menu-i .bars {
    display: block;
    margin: auto;
    margin-top: 13px;
    width: 24px;
}
.menu-i .bars .bar1, .menu-i .bars .bar2, .menu-i .bars .bar3 {
    display: block;
    content: "";
    height: 2px;
    width: 100%;
    margin-bottom: 4px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
     background:scotch-color('primary', 'base');
                background: -webkit-linear-gradient(left, scotch-color('primary', 'base') 0,scotch-color('primary', 'light') 100%);
                background: -moz-linear-gradient(left, scotch-color('primary', 'base') 0,scotch-color('primary', 'light') 100%);
                background: linear-gradient(to right, scotch-color('primary', 'base') 0,scotch-color('primary', 'light') 100%);
}
.menu-i.open-nav .bars .bar1, 
.menu-i.open-nav .bars .bar2, 
.menu-i.open-nav .bars .bar3 {
    height: 2px;
    width: 100%;
    margin-bottom: 4px;
    border-radius: 0;
}
.menu-i .bars .bar3 {
    width: 16px;
    &:hover{
        width: 100%;
    }
}
.menu-i .bars .bar1{
    &:hover{
        width: 16px;
    }
}

.page-header-alpha .navbar-toggler:focus {
    outline: none;
}
//OPEN
.menu-i.open-nav .bars {
    width: 24px;
    margin-top: 13px;
}
.menu-i.open-nav .bars .bar1 {
    -webkit-transform: translateY(6px) rotate(45deg);
    transform: translateY(6px) rotate(45deg);
    -webkit-transform-origin: center;
    transform-origin: center;
    width: 24px;
}
.menu-i.open-nav .bars .bar2 {
    width: 0px;
}

.menu-i.open-nav .bars .bar3 {
    -webkit-transform: translateY(-6px) rotate(-45deg);
    transform: translateY(-6px) rotate(-45deg);
    -webkit-transform-origin: center;
    transform-origin: center;
    width: 24px;
}

.page-header-alpha .all-menu-wrapper.menu-visible {
    top: 0;
    opacity: 1;
    visibility: visible;
}

