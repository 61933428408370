.has-text-centered {
    text-align: center !important;
}
.hr{
  margin: 50px 0;
  color: rgba(0,0,0,0.1);
  background:rgba(0,0,0,0.1);
      opacity: 0.1;
}
.text-upper{

  text-transform: uppercase;
}
.w-40{
  width: 40%;
  
}
.img-responsive{
      display: block;
    max-width: 100%;
    height: auto;

}
.progress-line{
  width: 100%;
  height: 8px;
}

.m-r-none{
  margin-right: 0 !important;
}
.text-white{
  color: #ffffff!important;
}
.text-black{
  color:  scotch-color('black', 'base') !important;
}
.text-main{
  color:  scotch-color('black', 'dark') !important;
}
.text-sub{
  color: scotch-color('black', 'light') !important;
}
.text-sub_light{
  color: scotch-color('black', 'light', 'dark') !important;
}
.width-100p, .width-full {
    width: 100%;
}
.pull-right{
  text-align: right;
}
.pull-left{
  text-align: left;
}
.mt-auto{
  margin-top: auto;
}
.mr-auto{
  margin-right: auto;
}
.ml-auto{
  margin-left: auto;
}
.mb-auto{
  margin-bottom: auto;
}
.no-margin-b{
  margin-bottom: 0px !important;
}
.no-margin{
  margin: 0 !important;
}
.no-padding{
  padding: 0 !important;
}
.f-bold{
  @include font-bold();
}
.f-light{
  @include font-light();
}
.f-bold-rounded{
  @include font-bold-rounded();
}
.f-light-rounded{
  @include font-light-rounded();
}
.d-content{
    display: contents;
}
@mixin parrafo($f: 16px, 
               $fw: 400, 
               $lh: 25px, 
               $m:  0 0 24px 0,
               $p: 0px, 
               $bold: false,
               $lato: false,
               $c: rgba(0,0,0,.54)){
    font-size: $f;
    font-weight: $fw;
    line-height: $lh;
    margin: $m;
    padding: $p;
    color: $c;
    @if $bold == true {
        @include font-bold-rounded();
    }@else if $lato == true{
          font-family: Lato,"sans-serif";
    }
}
.bg-code{
    background-color: scotch-color('text', 'light-key');
    color: scotch-color('pink', 'light');
    padding: 7px;
    border-radius: 5%;
    margin-left: 5px;

}
.o-text{
  line-height: 1.7;
  font-weight: 400;
  font-size: fontSize(h5);
}
.bg-code-sub{
    background-color: scotch-color('text', 'light-key');
    color: scotch-color('primary', 'light');
    padding: 7px;
    border-radius: 5%;
    margin-left: 5px;

}
.display-inline-block{
  display: inline-block !important;
}
.ali{
      position: relative;
    right: -9px;
    top: 7px;
}
.d-flex-align{
  display: flex;
    justify-content: center;
    align-items: center;
}
.sub-text{
    @include parrafo();
}
.sub-text-no-m{
    @include parrafo($m: 0);
}
@mixin a(){
  a{  
   color: scotch-color('text', 'for-text');
    &:visited{
    color: scotch-color('text', 'for-text');
    }
    &:hover{
    color: scotch-color('black', 'light'); 
    }
    &:focus{
    color: scotch-color('black', 'light'); 
    }
    &:active{
    color: scotch-color('black', 'light'); 
    }
  }
}
$spaceamounts: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }
  
    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}
.m-all-70{
margin: 70px;
}


@mixin font-weight($weight) {
  $weights: (
    thin: 100,
    extra-light: 200,
    ultra-light: 200,
    light: 300,
    normal: 400,
    book: 400,
    regular: 400,
    medium: 500,
    semi-bold: 600,
    demi-bold: 600,
    bold: 700,
    extra-bold: 800,
    ultra-bold: 900,
    heavy: 900,
    black: 900,
    ultra: 900,
    ultra-black: 900,
    extra-ultra: 900
  );
  
  $output: $weight;
  @if map-has-key($weights, $weight) {
    $output: map-get($weights, $weight);
  }
  
  font-weight: $output;
}

.w100 {
  @include font-weight(thin);
}
.w200 {
  @include font-weight(extra-light);
}
.w300 {
  @include font-weight(light);
}
.w400 {
  @include font-weight(book);
}
.w500 {
  @include font-weight(medium);
}
.w600 {
  @include font-weight(semi-bold);
}
.w700 {
  @include font-weight(bold);
}
.w800 {
  @include font-weight(extra-bold);
}
.w900 {
  @include font-weight(ultra-bold);
}


/* 6.2 Helper classes */
.display-1 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1; }
  @media (min-width: 768px) {
    .display-1 {
      font-size: 6rem; } }

.display-2 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1; }
  @media (min-width: 768px) {
    .display-2 {
      font-size: 5.5rem; } }

.display-3 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.1; }
  @media (min-width: 768px) {
    .display-3 {
      font-size: 4.5rem; } }

.display-4 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.1; }
  @media (min-width: 768px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.1; }
  @media (min-width: 768px) {
    .display-5 {
      font-size: 3rem; } }

@media (min-width: 768px){
.text-md-left {
    text-align: left!important;
}
}

.section.bg-bright {
    color: #1d1d1d;
}

.bg-white {
    background-color: #ffffff !important;
}
.section {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.section-title {
    max-width: 512px;
    margin: 0 auto;
    margin-bottom: 16px;
}
$var: 20;
@for $i from 1 through $var {
  .amd-#{$i} {
    animation-delay: .11s * $i !important;
 
  }
}