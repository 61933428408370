	/*=========================================================================
   	---------------------------   @Media: 1480px   ----------------------------  
	=========================================================================*/  	
	@include mq('desktop-wide-xs', $type: max) // 1480px
	{

	}
	/*=========================================================================
   	---------------------------   @Media: 1440px   ----------------------------  
	=========================================================================*/  
	@include mq('desktop-wide', $type: max) // 1440px
	{

	}
	/*=========================================================================
   	---------------------------   @Media: 1350px   ----------------------------  
	=========================================================================*/  
	@include mq('desktop-mediun', $type: max) // 1350px
	{

	}
	/*=========================================================================
   	---------------------------   @Media: 1248px   ----------------------------  
	=========================================================================*/  
	@include mq('desktop', $type: max) // 1248px
	{
		.wave {
			width: 280px;
			height: 280px;
			left: 50%;
			top: 10%;
		}
	}
	/*=========================================================================
   	---------------------------   @Media: 1024px   ----------------------------  
	=========================================================================*/  
	@include mq('tablet-wide', $type: max) // 1024px
	{
		.wave {
			width: 220px;
			height: 220px;
			left: 62%;
    		top: 17%;
		}
	}
	/*=========================================================================
   	---------------------------   @Media: 768px   ----------------------------  
	=========================================================================*/  
	@include mq('tablet', $type: max) // 768px
	{
		.section-status{
			.status{
				.text-right{
					text-align: center;
				}
				.text-left{
					text-align: center;
				}
			} 
			.position-relative{
				.mw-100{
					max-width: 50% !important;
				    position: relative;
				    display: flex;
				    justify-content: center;
				    margin: 0 auto;	
				}
			}
		} 
		.wave {
		   display: none;
		}
		.wave.-two{
			display: none;
		}


	}
	/*=========================================================================
   	---------------------------   @Media: 640px   ----------------------------  
	=========================================================================*/  
	@include mq('tablet-small', $type: max) // 640px
	{

	}
	/*=========================================================================
   	---------------------------   @Media: 560px   ----------------------------  
	=========================================================================*/  
	@include mq('phablet', $type: max) // 560px
	{
	
	}
	/*=========================================================================
   	---------------------------   @Media: 480px   ----------------------------  
	=========================================================================*/  
	@include mq('phone-wide', $type: max) // 480px
	{
	
	}
	/*=========================================================================
   	---------------------------   @Media: 400px   ----------------------------  
	=========================================================================*/  
	@include mq('phone', $type: max) // 400px
	{
		
	}
	/*=========================================================================
   	---------------------------   @Media: 340px   ----------------------------  
	=========================================================================*/  
	@include mq('phone-small', $type: max) // 340px
	{

	}
	/*=========================================================================
   	---------------------------   @Media: 320px   ----------------------------  
	=========================================================================*/  
	@include mq('phone-min-small', $type: max) // 320px
	{

	}
	/*=========================================================================
   	---------------------------   @Media: 260px   ----------------------------  
	=========================================================================*/  
	@include mq('phone-min', $type: max) // 260px
	{

	}