// Page head 
.page-cover{
	width: 100%;
    position: fixed;
    height: 120%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;
    .bg-cover {
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    z-index: -1;
	    &.grandient-primary{
		background-image: linear-grandient(to left, scotch-color('danger', 'base'), scotch-color('danger', 'light'));
		background-image: -webkit-gradient(linear, right top, left top, from(scotch-color('primary', 'base')), to(scotch-color('primary', 'light')));
		}
	}
	.bg-cover-mask{
		position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	}

}