@media (min-width: 768px){
    .page-header-alpha {
        padding: 0;
    }
}
@media (min-width: 768px){
.page-header-alpha .navbar-toggler {
    display: none;
    visibility: hidden;
}
}


@media (min-width: 768px){
.page-header-alpha.menu-right .navbar-toggler {
    top: 54px;
    left: auto;
    right: 56px;
}
}
@media (min-width: 768px){
.page-header-alpha .navbar-toggler {
    top: 54px;
    margin-top: -20px;
    left: 56px;
    right: auto;
    -webkit-box-shadow: none;
    box-shadow: none;
}
}

  @media (min-width: 768px){
.page-header-alpha .navbar-brand {
    left: 64px;
}
}
@media (min-width: 768px){
.page-header-alpha.menu-right .navbar-brand {
    left: 64px;
}
}
@media (min-width: 768px){
.page-header-alpha .navbar-brand {
    position: fixed;
    left: 120px;
    top: 24px;
    z-index: 20;
}
}

@media (min-width: 768px){
.page-header-alpha .navbar-brand .logo img {
    height: 48px;
}
}

 @media (min-width: 768px) {
      .page-header-alpha  .navbar-topmenu {
        right: 64px;
        }
    }
    @media (min-width: 768px) {
   .page-header-alpha .navbar-topmenu {
        right: 120px;
    }
     }
    @media (min-width: 768px){
   .page-header-alpha  .navbar-topmenu {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        position: fixed;
        top: 32px;
        right: 64px;
        z-index: 10;
    }
     }

     @media (min-width: 768px){
.page-header-alpha .navbar-topmenu:before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    height: 0;
    width: 100%;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    z-index: -1;
}
}

    @media (min-width: 768px){
	.page-header-alpha .navbar-topmenu .navbar-nav {
	     -webkit-box-orient: horizontal; 
	    -webkit-box-direction: normal; 
	    -ms-flex-direction: row;
	    flex-direction: row;
	}
}


@media (min-width: 768px){
.page-header-alpha .navbar-topmenu .navbar-nav-menu .nav-item:first-child {
    border-left: none;
}
}
@media (min-width: 768px){
.page-header-alpha .navbar-topmenu .navbar-nav-menu .nav-item {
    line-height: 1;
    font-size: 1rem;
    position: relative;
}
}


@media (min-width: 768px){
.page-header-alpha .navbar-topmenu .navbar-nav-menu .nav-item a {
    padding: 8px 16px;
}
}
@media (min-width: 768px){
.page-header-alpha.scroll  .navbar-topmenu:before {
    background-color: #ffffff;
    -webkit-box-shadow: 2px 0 8px rgba(0, 0, 0, 0.25);
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.25);
}
}
@media (min-width: 768px){
.page-header-alpha.scroll .navbar-topmenu:before {
    height: 97px;
}
}

@media (min-width: 768px){
.page-header-alpha .all-menu-wrapper {
    display: block;
    position: static;
    border-bottom: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0;
    top: 0;
    opacity: 1;
    visibility: visible;
}}

