$p-section:  padding-global(p1) + padding-global(p2) + padding-global(p2) 
			 padding-global(p1) 
			 padding-global(p1) + padding-global(p1) + padding-global(p1) 
			 padding-global(p1);

$m-section: margin-global(m1) + margin-global(m1);
@import '_head';
@import '_navbar';
@import '_main';
@import '_description';
@import '_owl';
@import '_details';
@import '_status';
@import '_get';
@import '_who';
@import '_contact';
@import '_screenshots';
@import '_sub-description';
@import '_footer';
//Responsive
.padding-main{
   padding-top: padding-global(p1) + padding-global(p2);
   padding-bottom: padding-global(p1) + padding-global(p2); 
}
.section_white{
    background-color: #ffffff;
}
.main-title{
	text-align: center;
	padding: 0 padding-global(p3);
	font-size: prisma-sz(h1);
	text-transform: uppercase;
	font-weight: 900;
	letter-spacing: 2px;

}
.page-header-alpha.scroll .navbar-nav-menu .nav-item a.active{
    color: #41494f;
        opacity: 1;
}
.title-main-text{
    display: flex;
    justify-content: center;
    text-align: center;
}
.section-title-01 {
    text-align: left;
    position: relative;
    z-index: 1;
}
.section-title-01 .subtitle {
    color: scotch-color('app', '1');
    font-size: 80px;
    font-weight: 700;
    left: 0;
    line-height: 1;
    opacity: .02;
    position: absolute;
    text-transform: uppercase;
    top: 50%;
    transform: translateY(-50%);
}
.section-title h2, .section-title-01 h2 {
    color: scotch-color('app', '2');
    font-size: 38px;
    font-weight: 400;
    margin-bottom: 15px;
}
.gradient-text-02 {
    background:scotch-color('primary', 'base');
    background: -webkit-linear-gradient(left, scotch-color('primary', 'base') 0,scotch-color('primary', 'light') 100%);
    background: -moz-linear-gradient(left, scotch-color('primary', 'base') 0,scotch-color('primary', 'light') 100%);
    background: linear-gradient(to right, scotch-color('primary', 'base') 0,scotch-color('primary', 'light') 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f54ea2',endColorstr='#ff7676',GradientType=1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
hr.hr-title-03 {
    background: scotch-color('primary', 'base');
    background: -webkit-linear-gradient(left, scotch-color('primary', 'base') 0,scotch-color('primary', 'light') 100%);
    background: -moz-linear-gradient(left, scotch-color('primary', 'base') 0,scotch-color('primary', 'light') 100%);
    background: linear-gradient(to right, scotch-color('primary', 'base') 0,scotch-color('primary', 'light') 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f54ea2',endColorstr='#ff7676',GradientType=1);
    width: 160px;
    border: 0;
    height: 2px;
    margin-left: 0;
    margin-bottom: 50px;
}
// Title New Text
.section-title-02 {
   
    position: relative;
    z-index: 1;
}
.section-title-02 .subtitle {
    color: #fff;
    font-size: 80px;
    font-weight: 700;
    left: 50%;
    line-height: 1;
    opacity: .12;
    position: absolute;
    text-transform: uppercase;
    top: -10%;
    transform: translateY(-50%);
    transform: translateX(-50%);
}
 .section-title-02 h2 {
    color: #fff;
    font-size: 38px;
    font-weight: 400;
    margin-bottom: 15px;
}
.gradient-text-03 {
    background:scotch-color('primary', 'base');
    background: -webkit-linear-gradient(left, #ffffff 0, #aae0ff 100%);
    background: -moz-linear-gradient(left, #ffffff 0, #aae0ff 100%);
    background: linear-gradient(to right, #ffffff 0, #aae0ff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f54ea2',endColorstr='#ff7676',GradientType=1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
hr.hr-title-04 {
    background: scotch-color('primary', 'base');
    background: -webkit-linear-gradient(left,  #ffffff 0, #aae0ff 100%);
    background: -moz-linear-gradient(left,  #ffffff 0, #aae0ff 100%);
    background: linear-gradient(to right,  #ffffff 0, #aae0ff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f54ea2',endColorstr='#ff7676',GradientType=1);
    width: 160px;
    border: 0;
    height: 2px;
    margin-left: 0;
    margin-bottom: 50px;
}
.section-p{
    padding: padding-global(p4);
}

.text-gradient-primary {
    background-image: -webkit-gradient(linear, right top, left top, from(scotch-color('primary', 'base')), to(scotch-color('primary', 'light')));
    /* background-image: linear-gradient(to left, #D1335B, #ff635d); */
    background-size: cover;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
}
.text-only-gradient-second{
        background-image: -webkit-gradient(linear, right top, left top, from(#ffffff), to(#aae0ff));
    /* background-image: linear-gradient(to left, #D1335B, #ff635d); */
    background-size: cover;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
}
.text-gradient-second{
        background-image: -webkit-gradient(linear, right top, left top, from(#ffffff), to(scotch-color('primary', 'light')));
    /* background-image: linear-gradient(to left, #D1335B, #ff635d); */
    background-size: cover;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
}
.padding-s{

    padding-top: padding-global(p4);
    padding-bottom: padding-global(p4);

}
.contact-padding{
    
    padding: padding-global(p4) padding-global(p4) padding-global(p1) + padding-global(p1) + padding-global(p1) + padding-global(p4) padding-global(p4);

}

.go-top {
    background: #19abd6;
    background: -webkit-gradient(linear, right top, left top, from(scotch-color('primary', 'light')), to(scotch-color('primary', 'base')));

    background: -moz-linear-gradient(left,scotch-color('primary', 'light') -5%, scotch-color('primary', 'base') 100%);
    background: linear-gradient(to right,scotch-color('primary', 'light') -5%, scotch-color('primary', 'base') 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#19abd6',endColorstr='#1ec8fc',GradientType=1);
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: #fff;
    position: fixed;
    bottom: 1em;
    right: 1em;
    text-decoration: none;
    font-size: 24px;
    text-align: center;
    line-height: 48px;
    transform: scale(0);
    @include trans(transform .3s, $cubic-1);
    background-size: 200% auto;
    will-change: transform;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    i{
        font-size: 1.1em;
    }
    &.expand{
       transform: scale(1);
    }
}

.mouse-whell{
    position: absolute;
    /* display: flex; */
    /* justify-content: left; */
    /* align-items: center; */
    /* width: 100%; */
    bottom: 68px;
    

    animation: 1.5s fadeup $cubic-1;
    .mouse{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: transparent;
        border: 1.3px solid  text(white , 50);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        @include trans(color .7s, $cubic-1);
        &:after{
                content: "";
                width: 50px;
                height:50px;
                border-radius: 70%;
                position: absolute;
                left: 0;
                top: 0;
                color: #736cf5;
                z-index: 1;
                border: 1.9px solid #fff;
             
                animation: circle_zoom  1.5s  $cubic-1 forwards;



        }
        &:hover{
            border-color: text(white , 70)
        }
        i{
             @include trans(color .7s, $cubic-1);
             animation: .7s fadeup $cubic-1;
            color: text(white , 50);
            font-size: 20px;
            &:hover{
               border-color: text(white , 70)  
            }
        }

    }

}
@keyframes fadeup {
    0%{
        opacity: 0;
        transform: scale(0);
    }
    100%{
        opacity: 1;
         transform: scale(1);
    }
  }
@-webkit-keyframes circle_zoom {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: .6
    }
    100% {
        -webkit-transform: scale(2);
        transform: scale(2);
        opacity: 0
    }
}
.owl-prev{
    position: absolute;
    left: -60px;
    background: red;
    top: 50%;
    transform: translate(-50%, -50%);
}
.owl-next{
    position: absolute;
    right: -60px;
    background: red;
    top: 50%;
    transform: translate(-50%, -50%);

}
.owl-nav{
    span{
            font-size: 63px;
            color: #ffffff;
    }
}
@import '_responsive/_responsive';
@import '_responsive/_main-r';
@import '_responsive/_description';
@import '_responsive/_navbar';
@import '_responsive/_details';
@import '_responsive/_main';
@import '_responsive/_swiper';
@import '_responsive/_status';
@import '_responsive/_who';