.section-app{
	background: scotch-color('primary', 'base');
    background: -webkit-linear-gradient(left, scotch-color('primary', 'base') 0,scotch-color('primary', 'light') 100%);
    background: -moz-linear-gradient(left, scotch-color('primary', 'base') 0,scotch-color('primary', 'light') 100%);
    background: linear-gradient(to right, scotch-color('primary', 'base') 0,scotch-color('primary', 'light') 100%);
	&:before{
		content: "";
	    display: block;
	    position: absolute;
	    left: 0;
	    top: 0;
	    z-index: 1;
	    width: 100%;
	    height: 100%;
	    background: url(../img/pattern.png);
	}
	.btn-d{
		position: relative;
		z-index: 2;
		background: scotch-color('primary', 'base');
	    background: -webkit-linear-gradient(left, scotch-color('danger', 'base') 0,scotch-color('danger', 'light') 100%);
	    background: -moz-linear-gradient(left, scotch-color('danger', 'base') 0,scotch-color('danger', 'light') 100%);
	    background: linear-gradient(to right, scotch-color('danger', 'base') 0,scotch-color('danger', 'light') 100%);
	    @include shadow(1.75);
	    cursor: pointer;
	    margin-top: margin-global(m4);
	    &:hover{
	    	@include shadow(1.5);
	    	border: none;
	    }
	}
}