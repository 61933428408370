$text: (
	black:(
		100:   rgba(0,0,0,1),
		90:    rgba(0,0,0,0.9),
		80:    rgba(0,0,0,0.8),
		70:    rgba(0,0,0,0.7),
		60:    rgba(0,0,0,0.6),
		50:    rgba(0,0,0,0.5),
		40:    rgba(0,0,0,0.4),
		30:    rgba(0,0,0,0.3),
		20:    rgba(0,0,0,0.2),
		10:    rgba(0,0,0,0.1)
	),
	white:(
		100:   rgba(255,255,255,1),
		90:    rgba(255,255,255,0.9),
		80:    rgba(255,255,255,0.8),
		70:    rgba(255,255,255,0.7),
		60:    rgba(255,255,255,0.6),
		50:    rgba(255,255,255,0.5),
		40:    rgba(255,255,255,0.4),
		30:    rgba(255,255,255,0.3),
		20:    rgba(255,255,255,0.2),
		10:    rgba(255,255,255,0.1)
	)
);

 
// retrieve color from map ie. `color(primary, base)`
@function text($color-name, $color-variant:null) {
  // color variant is optional
  @if ($color-variant != null) {
    // map inception
    @return map-get(map-get($text, $color-name), $color-variant);
  } @else {
    @return map-get($text, $color-name);
  }
}

.c{
	color: text(white , 50);
}