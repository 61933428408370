.section-contact{
	h4{
		margin-top: 0;
		font-weight: 400;
		color: #fff;
		font-size: fontSize(h3);
		margin-bottom: margin-global(m1) + margin-global(m3);
	}
	.newsletter{
		margin-bottom: margin-global(m1) + margin-global(m3);
		 .newsletter input {
		    font-size: 16px;
		    padding: 15px 10px 15px 15px;
		    display: block;
		    width: 100%;
		    border-radius: 0;
		    border: 0 none;
		    border-bottom: 2px solid #eaedf2;
		    color: #969696;
		    transition: all 1s ease;
		}
		.form-control, input {
		    border-width: 2px;
		    -webkit-box-shadow: none;
		    box-shadow: none;
		}
		.form-control {
		    display: block;
		    width: 100%;
		    height: 45px;
		    padding: 10px 15px;
		    font-size: 15px;
		    line-height: 1.42857143;
		   color: scotch-color('app', '2');
		    background-color: #fff;
		    background-image: none;
		    border: none;
		    border-radius: 4px;
		    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
		    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
		    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
		    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
		    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
		}
		.subscribe-btn {
			    background: #aae0ff;
			    background: -moz-linear-gradient(-45deg,  #ffffff 10%,#aae0ff 100%);
			    background: -webkit-linear-gradient(-45deg,  #ffffff 10%,#aae0ff 100%);
			    background: linear-gradient(135deg,  #ffffff 10%,#aae0ff 100%);
			    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f54ea2',endColorstr='#ff7676',GradientType=1);
			    display: inline-block;
			    margin: 0 auto;
			    overflow: hidden;
			    width: 90px;
			    height: 90px;
			    border-radius: 50%;
			    @include trans(box-shadow .3s, $cubic-1);
			    display: flex;
			    justify-content:center;
			    align-content: center;
			    color: scotch-color('app', '2');
		    	 i{
					    transform: rotate(-50deg) translateX(3px);
					    opacity: .5;
					    font-size: 2.8em;
					    display: flex;
					    align-items: center;
					    justify-content:center;

		    	 }

			}	
			.subscribe-btn:hover {
			    -webkit-box-shadow: 0 0 30px #aae0ff;
			    -moz-box-shadow: 0 0 30px #aae0ff;
			    box-shadow: 0 0 30px #aae0ff;
			}
	}
}