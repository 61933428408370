	/*=========================================================================
   	---------------------------   @Media: 1480px   ----------------------------  
	=========================================================================*/  	
	@include mq('desktop-wide-xs', $type: max) // 1480px
	{

	}
	/*=========================================================================
   	---------------------------   @Media: 1440px   ----------------------------  
	=========================================================================*/  
	@include mq('desktop-wide', $type: max) // 1440px
	{

	}
	/*=========================================================================
   	---------------------------   @Media: 1350px   ----------------------------  
	=========================================================================*/  
	@include mq('desktop-mediun', $type: max) // 1350px
	{

	}
	/*=========================================================================
   	---------------------------   @Media: 1248px   ----------------------------  
	=========================================================================*/  
	@include mq('desktop', $type: max) // 1248px
	{

	}
	/*=========================================================================
   	---------------------------   @Media: 1024px   ----------------------------  
	=========================================================================*/  
	@include mq('tablet-wide', $type: max) // 1024px
	{
		.screen_wrap {
    		width: 720px;
    	}
	}
	/*=========================================================================
   	---------------------------   @Media: 768px   ----------------------------  
	=========================================================================*/  
	@include mq('tablet', $type: max) // 768px
	{
		.screen_wrap {
    		width:650px;
    	}
    	.landing-cta-2{
    		background-size: contain
    	}
	}
	/*=========================================================================
   	---------------------------   @Media: 640px   ----------------------------  
	=========================================================================*/  
	@include mq('tablet-small', $type: max) // 640px
	{
		.screen_wrap {
    		width:550px;
    	}
    	.nav-footer-f{
    		flex-wrap: wrap;
    		li{
    			margin-bottom: margin-global(m3);
    			    margin-left: margin-global(m4);
    		}
    	}
	}
	/*=========================================================================
   	---------------------------   @Media: 560px   ----------------------------  
	=========================================================================*/  
	@include mq('phablet', $type: max) // 560px
	{

	}
	/*=========================================================================
   	---------------------------   @Media: 480px   ----------------------------  
	=========================================================================*/  
	@include mq('phone-wide', $type: max) // 480px
	{
		.screen_wrap {
    		  width: 420px;
    	}
    	.nav-footer-f{
    		flex-wrap: wrap;
    		li{
    			margin-bottom: margin-global(m5);
    			    margin-left: 0;
    		}
    	}
  
	}
	/*=========================================================================
   	---------------------------   @Media: 400px   ----------------------------  
	=========================================================================*/  
	@include mq('phone', $type: max) // 400px
	{
		.screen_wrap {
    		  width: 320px;
    	}
	}
	/*=========================================================================
   	---------------------------   @Media: 340px   ----------------------------  
	=========================================================================*/  
	@include mq('phone-small', $type: max) // 340px
	{

	}
	/*=========================================================================
   	---------------------------   @Media: 320px   ----------------------------  
	=========================================================================*/  
	@include mq('phone-min-small', $type: max) // 320px
	{

	}
	/*=========================================================================
   	---------------------------   @Media: 260px   ----------------------------  
	=========================================================================*/  
	@include mq('phone-min', $type: max) // 260px
	{

	}								