.section-datils{
	background-color: #fff;
	.img-flex{
		.details-img{
			   width: 100%;
			    height: 100%;
			    background: url(../img/mockup-2.png);
			    background-position: center center;
			    background-size: contain;
			    background-repeat: no-repeat;	
		}
	}
	.spacing-col{
		margin: margin-global(m1) + margin-global(m1) 0;
	}
	.best-title{
		font-size: 5vw;
		font-weight: 900;
		line-height: 1;
		letter-spacing: 2px;
		color: scotch-color('app', '2');
		position: relative;
		z-index: 3;
	}
	.icon-rounder{
		width: 90px;
		height: 90px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;

		@include shadow(1.5);
		@include trans(box-shadow .35s, $cubic-1);
		
		&:hover{
			@include shadow(20);

		}
		i{
			font-size: fontSize(h1);
			color: scotch-color('app', '2');
			opacity: .5;
		}
	}
	.avatar-content {
	margin-top: margin-global(m4) + margin-global(m6);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: .875rem .875rem .875rem 0;
}
	.avatar-content .avatar-img {
	    -webkit-box-flex: 0;
	    -ms-flex: 0 0 auto;
	    flex: 0 0 auto;
	    margin-right: 34px;
	}
	.avatar-content .avatar-text {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: padding-global(p8) 0;
}
}

 